import React from "react"
import { graphql } from 'gatsby'

import Layout from "../components/layout"
import { Hero, HeroText } from "../components/Hero"
import { LeftContainer, RightContainer } from "../components/Templates"

import { Contact } from "../components/contact-us"
import SEO from "../components/seo"

const NosClients = ({ data }) => (
  <Layout>
    <SEO title="Réalisations" />

    <Hero fluid={data.hero.edges[0].node.fluid}>
      <HeroText text="Réalisations"/>
    </Hero>

<RightContainer
      imageSrc="Saadify/artisan_electrician-1080554_1920.jpg"
      header="Demo - Home Services"
      text = {[ "Entreprise du batiment à destination des particuliers et professionnels. Site web réalisé à des fins de démonstrations.",
                "Promotions des services d'un artisant professionnel qu'ils soient destinés à des particuliers ou des professionnels. Mise en avant des services proposés et des réalisations déjà effectuées par le passé. Mise en place d'un formulaire de contact."
               ]}
      button = {["https://d2dbgz8e6le2zd.cloudfront.net/", "Voir le site"]}
    />

<LeftContainer
      imageSrc="Saadify/mercedes-benz-parked-in-a-row-164634.jpg"
      header="Demo - Car Prestige"
      text = {[ "Locations de voitures / Prestations chauffeur. Site web réalisé à des fins de démonstrations.",
                "Loueur de véhicules pour de la courte durée et de la longue durée. Mise en avant des différents véhicules à disposition. Promotion des services de voiture avec chauffeur. Mise en place d'un formulaire de contact."
               ]}
      button = {["https://d3b3bm8eq2ejpa.cloudfront.net/", "Voir le site"]}
    />

    <RightContainer
      imageSrc="Saadify/undraw_under_construction_46pa.png"
      header="Vous ?"
      text={[ "Si vous souhaitez en savoir plus et obtenir un devis, n'hésitez pas à nous contacter.",
            ]}
    />

    <Contact
      contactButton = {[
        "/contact",
        "Contact"
      ]}
    />
  </Layout>
)

export default NosClients

export const pageQuery = graphql`
  query {
    hero: allImageSharp(filter: { original: { src: { regex: "/headway-5QgIuuBxKwM-unsplash/" } } }) {
      edges {
        node {
          id
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  }
`;
