import React from "react"
import styled from "styled-components"
import Image from "./image"
import { Link } from "gatsby"

const LeftContainerBox = styled.div`
  margin: 40px auto;
  display: flex;
  flex-direction: column;
  flex-flow: row wrap;
  align-items: stretch;
  -webkit-box-align: stretch;
  width: 90vw;
`

const RightContainerBox = styled(LeftContainerBox)`
  @media (max-width: 768px) {
    flex-direction: column-reverse;
  }
`


const ImageDiv = styled.div`
  width: 50%;
  margin: auto;

  @media (max-width: 768px) {
    padding: 2%;
    text-align: center;
    width: 100%;
  }
`

const ImageGridDiv = styled.div`
  width: 90%;
  margin: auto;

  @media (max-width: 768px) {
    padding: 2%;
    text-align: center;
    width: 100%;
  }
`

const TextDiv = styled.div`
  margin: auto;
  width: 50%;
  text-align: center;
  padding: 60px;
  white-space: pre-wrap;

  @media (max-width: 768px) {
    padding: 2%;
    text-align: center;
    width: 100%;
  }
`

const DualContainerBox = styled.div`
  margin: auto;
  display: flex;
  flex-direction: column;
  flex-flow: row wrap;
  align-items: stretch;
  -webkit-box-align: stretch;
  width: 90vw;
`

const LeftDiv = styled(TextDiv)`
  padding-top: 0px;
`
const RightDiv = styled(LeftDiv)``

const TripleContainer = styled(DualContainerBox)`
  padding-top: 40px;
  display: table;
`

const TripleItem = styled.div`
  display: table-cell; 
  margin: auto;
  width: 10%;
  text-align: center;
  padding: 0 20px;

  
  @media (max-width: 768px) {
    padding: 2%;
    text-align: center;
    width: 100%;
    display: block;
  }
`

const QuadContainer = styled(DualContainerBox)`
  padding-top: 10px;
  // border: solid;
`
const QuadItem = styled.div`
  padding-bottom: 100px;
  margin: auto;
  text-align: center;
  width: 45%;
  // border: solid;

  @media (max-width: 768px) {
    margin: auto;
    padding: 2%;
    text-align: center;
    width: 100%;
`


export const LeftContainer = ({ imageSrc, header, text, button }) => (
  <leftContainer>
    <LeftContainerBox data-sal="slide-left" data-sal-duration="300" data-sal-eaesing="ease-in">
      <ImageDiv>
        <Image src={imageSrc}/>
      </ImageDiv>
      <TextDiv>
        <h2>{header}</h2>
        <div>
          { text.map( (t) => { return <p>{t}</p>; })}
        </div>
        { button ? <Link to={button[0]} className="btn btn-1">{button[1]}</Link> : null }
      </TextDiv>
    </LeftContainerBox>
  </leftContainer>
)

export const RightContainer = ({ imageSrc, header, text, button }) => (
  <rightContainer>
    <RightContainerBox data-sal="slide-right" data-sal-duration="300" data-sal-eaesing="ease-in">
      <TextDiv>
        <h2>{header}</h2>
        <div>
          { text.map( (t) => { return <p>{t}</p>; })}
        </div>
        { button ? <Link to={button[0]} className="btn btn-1">{button[1]}</Link> : null }
      </TextDiv>
      <ImageDiv>
        <Image src={imageSrc}/>
      </ImageDiv>
    </RightContainerBox>
  </rightContainer>
)

export const DualContainer = ({ header, header1, header2, imageSrc1, imageSrc2, text1, text2, button1, button2 }) => (
  <dualContainer>
    <h1 style={{ textAlign: 'center', paddingTop: '50px'}}>{header}</h1>
    <DualContainerBox>
    <LeftDiv>
        <h2>{header1}</h2>
        <Image src={imageSrc1}/>
        <br/>
        <div>
          { text1.map( (t) => { return <p>{t}</p>; })}
        </div>
        { button1 ? <Link to={button1[0]} className="btn btn-1">{button1[1]}</Link> : null }
      </LeftDiv>
      <RightDiv>
        <h2>{header2}</h2>
        <Image src={imageSrc2}/>
        <br/>
        <div>
          { text2.map( (t) => { return <p>{t}</p>; })}
        </div>
        { button2 ? <Link to={button2[0]} className="btn btn-1">{button2[1]}</Link> : null }
      </RightDiv>
    </DualContainerBox>
  </dualContainer>
)

export const TripleText = () => (
  <tripleText data-sal="slide-up" data-sal-duration="500" data-sal-eaesing="ease-in">
    <h1 style={{ textAlign: 'center', paddingTop: '50px'}}>Nos Engagements</h1>
    <TripleContainer>
      <TripleItem>
        <h3 style={{ textAlign: 'center' }}>Satisfaction Client</h3>
        <div>
          <p>Notre mot d'ordre est d'aller au-delà de vos attentes.</p>
          <p>En vous proposant un service proche de vous, joignable à tout moment et personnalisé, vous bénéficiez d’une expérience sur mesure haut de gamme.</p>
        </div>
      </TripleItem>
      <TripleItem>
        <h3 style={{ textAlign: 'center' }}>Tarifs Compétitifs</h3>
        <div>
          <p>Afin que tous puissent bénéficier d’une visibilité en ligne, nous vous proposons une offre adaptée à des tarifs contenus.</p>
          <p>Notre expérience nous a permis de faire les bons choix afin d’optimiser les coûts et de proposer une offre tout inclus abordable.</p>
        </div>
      </TripleItem>
      <TripleItem>
        <h3 style={{ textAlign: 'center' }}>Livraison Rapide</h3>
        <div>
          <p>Pour que vous puissiez vous concentrer sur l’essentiel, nous vous promettons des délais de livraisons inférieurs à 7 jours.</p>
          <p>Avec un niveau de personnalisation élevé, nos sites web se basent sur des modules prédéfinis pour aller à l'essentiel.</p>
        </div>
      </TripleItem>
    </TripleContainer>
  </tripleText>
)

export const QuadGrid = () => (
  <quadGrid data-sal="slide-up" data-sal-duration="500" data-sal-eaesing="ease-in">
    <h1 style={{ textAlign: 'center', paddingTop: '50px'}}>Nos principes</h1>
    <QuadContainer>
      <QuadItem>
        <h3>Sécurité</h3>
        <div>
          <ImageGridDiv><Image src="Saadify/undraw_Security_on_ff2u.png" alt="Saadify - Sécurité"/></ImageGridDiv>
          <p>La sécurité sur internet est un enjeu primordial. C'est pour cela que tous nos sites webs sont sécurisés par de multiples moyens. Tous les sites disposent de certificats SSL et sont accessibles en HTTPS.</p>
        </div>
      </QuadItem>
      <QuadItem>
        <h3>Responsive Design</h3>
        <div>
        <ImageGridDiv><Image src="Saadify/undraw_web_devices_ad58.png" alt="Saadify - Responsive Design"/></ImageGridDiv>
          <p>Les téléphones et tablettes font maintenant partie de notre quotidien et il est essentiel d'avoir un site web adapté à cet usage. Tous nos sites sont dits "responsive" et s'adaptent automatiquement à l'affichage de l'utilisateur peu importe l'appareil sur lequel il se trouve.</p>
        </div>
      </QuadItem>
      <QuadItem>
        <h3>Performances</h3>
        <div>
        <ImageGridDiv><Image src="Saadify/undraw_fast_loading_0lbh.png" alt="Saadify - Performances"/></ImageGridDiv>
          <p>Pour un confort d’utilisation des visiteurs, il est essentiel d’avoir un site web performant qui se charge rapidement et qui soit sans erreurs. C’est pour cela que nous utilisons des outils technologiques à la pointe tels que AWS ou GatsbyJS.</p>
        </div>
      </QuadItem>
      <QuadItem>
        <h3>Suivi & Conseils</h3>
        <div>
        <ImageGridDiv><Image src="Saadify/undraw_conversation_h12g.png" alt="Saadify - Suivi et Conseils"/></ImageGridDiv>
          <p>Afin de vous accompagner efficacement, nous vous proposons un suivi régulier et sommes à votre disposition pour toute question ou demande d’expertise sur tous les sujets liés au web ou au numérique.</p>
        </div>
      </QuadItem>
    </QuadContainer>
  </quadGrid>
)