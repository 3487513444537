import { Link } from "gatsby"
import React from "react"
import styled from "styled-components"

const ContactBox = styled.div`
  background-color: Gainsboro;
  text-align: center;
  margin: 50px 0px;
  padding: 50px 20px;
  text-decoration: none;
  width: ${(props) => props.width || '100%'};
  @media (max-width: 768px) {
    width: 100%
  }

`

const Container = styled.div`
  text-align: center;
  display: flex;
  flex-direction: column;
  flex-flow: row wrap;
  align-items: stretch;
  -webkit-box-align: stretch;
`

const ContainerItem = styled.div`
  width: 50%;
  padding: 20px;

  @media (max-width: 768px) {
    width: 100%
  }
`

const ContainerItemLarge = styled(ContainerItem)`
  width: 100%;
`

const FormContainer = styled.div`
  width: ${(props) => props.width || '100%'};
  margin: auto;
  padding: 50px 20px;
  @media (max-width: 768px) {
    padding: 0px;
    width: 100%
  }

`

export const Contact = (props) => (
  <ContactBox width={props.width} data-sal="slide-up" data-sal-duration="300" data-sal-eaesing="ease-in">
    <h3>Nous sommes là pour vous</h3>
    <p>Notre mission est simple, vous permettre de toucher une audience plus importante via les nouvelles technologies. C'est ainsi que nous vous proposons la réalisations de sites internet que ce soit via nos packs pré-définis ou via une offre sur mesure.</p>
    <p>Nous vous proposons également une offre d'accompagnement via des formations aux nouveaux outils du numérique ainsi que de la publicité sur les diverses plateformes en ligne.</p>
    {/* <p><br/><Link to="https://wa.me/33665361307" className="whatsappbutton">Nous écrire via Whatsapp</Link></p> */}
    {/* <p>Vous pouvez nous joindre via notre formulaire de contact ou par téléphone au : 06 65 36 13 07</p> */}
    {/* <h4>Contactez-nous!</h4> */}
    { props.contactButton ? <Link to={props.contactButton[0]} className="btn btn-2">{props.contactButton[1]}</Link> : null }
  </ContactBox>
)

export const ContactForm = (props) => (
  <FormContainer width={props.width}>
    {/* <form action="https://formspree.io/f/xyylyvaa" method="POST" data-sal="slide-up" data-sal-duration="300" data-sal-eaesing="ease-in"> */}
    {/* <form action="https://mailthis.to/Saâd" method="POST" data-sal="slide-up" data-sal-duration="300" data-sal-eaesing="ease-in">
      <Container>
        <ContainerItem>
          <label>Nom
            <input type="text" name="Nom"/>
          </label>
        </ContainerItem>
        <ContainerItem>
          <label htmlFor="email">Email
            <input type="email" name="_replyto"/>
          </label>
        </ContainerItem>
      </Container>
      <Container>
        <ContainerItemLarge>
          <label htmlFor="message">Message
            <textarea type="text" name="Message"/>
          </label>
        </ContainerItemLarge>
        <button type="submit" class="btn btn-1">Envoyer</button>
      </Container>
    </form> */}
    <Container data-sal="slide-up" data-sal-duration="300" data-sal-eaesing="ease-in">
      <p>
        Vous pouvez nous contacter directement via Whatsapp en cliquant sur le bouton ci-dessous:<br/><br/>
      <Link to="https://wa.me/33665361307" className="whatsappbutton">Nous écrire via Whatsapp</Link>
      </p>
      <p>
        Pour nous contacter par email, vous pouvez nous écrire à l'adresse <a href="mailto:contact@saadify.fr">contact@saadify.fr</a>.
      </p>
      <p>
        Nous sommes également joignable au <a href="tel:+33665361307">+33 (0)6 65 36 13 07</a>.
      </p>
    </Container>
  </FormContainer>
)
